export default [
    {
        path: "/activitycollect",
        file: "views/activity/collect.vue",
    },
    {
        path: "/activityindex",
        file: "views/activity/index.vue",
    },
    {
        path: "/activityinterval",
        file: "views/activity/interval.vue",
    },
    {
        path: "/notificationrecord",
        file: "views/activity/notificationrecord.vue",
    },
    {
        path: "/activityrecord",
        file: "views/activity/record.vue",
    },
    {
        path: "/answerindex",
        file: "views/answer/index.vue",
    },
    {
        path: "/answerquestion",
        file: "views/answer/question.vue",
    },
    {
        path: "/answerrecord",
        file: "views/answer/record.vue",
    },
    {
        path: "/answerwinning",
        file: "views/answer/winning.vue",
    },
    {
        path: "/answerrang",
        file: "views/answer/answerrang.vue",
    },
    {
        path: "/luckdrawindex",
        file: "views/answer/luckdraw.vue",
    },
    {
        path: "/archivesassessment",
        file: "views/archives/assessment.vue",
    },
    {
        path: "/assessmentRecord",
        file: "views/archives/assessmentRecord.vue",
    },
    {
        path: "/archivesdemand",
        file: "views/archives/demand.vue",
    },
    {
        path: "/archivesdevservice",
        file: "views/archives/devservice.vue",
    },
    {
        path: "/archivesindex",
        file: "views/archives/index.vue",
    },
    {
        path: "/archivesmanage",
        file: "views/archives/manage.vue",
    },
    {
        path: "/archivesplan",
        file: "views/archives/plan.vue",
    },
    {
        path: "/archivesrecord",
        file: "views/archives/record.vue",
    },
    {
        path: "/archivesreferral",
        file: "views/archives/referral.vue",
    },
    {
        path: "/archivesreturnvisit",
        file: "views/archives/returnvisit.vue",
    },
    {
        path: "/returnvisitRecord",
        file: "views/archives/returnvisitRecord.vue",
    },
    {
        path: "/settingservice",
        file: "views/archives/settingservice.vue",
    },
    {
        path: "/archivesstopservice",
        file: "views/archives/stopservice.vue",
    },
    {
        path: "/area",
        file: "views/area/area.vue",
    },
    {
        path: "/authorizeindex",
        file: "views/authorize/index.vue",
    },
    {
        path: "/basebookindex",
        file: "views/basebook/index.vue",
    },
    {
        path: "/bigscreenindex",
        file: "views/bigscreen/index.vue",
    },
    {
        path: "/chartchannel",
        file: "views/chart/channel.vue",
    },
    {
        path: "/chartcommittee",
        file: "views/chart/committee.vue",
    },
    {
        path: "/chartoperate",
        file: "views/chart/operate.vue",
    },
    {
        path: "/chartuser",
        file: "views/chart/user.vue",
    },
    {
        path: "/childmanagement",
        file: "views/childmanagement/childmanagement.vue",
    },
    {
        path: "/announce",
        file: "views/column/children/announce.vue",
    },
    {
        path: "/newlabel",
        file: "views/column/children/labelSettings.vue",
    },
    {
        path: "/officialcolumn",
        file: "views/column/index.vue",
    },
    {
        path: "/communityindex",
        file: "views/community/index.vue",
    },
    {
        path: "/communityaccess",
        file: "views/communityaccess/index.vue",
    },
    {
        path: "/chartindex",
        file: "views/communityaccess/map.vue",
    },
    {
        path: "/recordsAcc",
        file: "views/communityaccess/records.vue",
    },
    {
        path: "/communityuser",
        file: "views/communityjoinusers/communityUser.vue",
    },
    {
        path: "/dimensionindex",
        file: "views/dimension/index.vue",
    },
    {
        path: "/discussionmanage",
        file: "views/discussion/discussionmanage.vue",
    },
    {
        path: "/discussionindex",
        file: "views/discussion/index.vue",
    },
    {
        path: "/discussionvotinglist",
        file: "views/discussion/votingList.vue",
    },
    {
        path: "/emailgroup",
        file: "views/email/group.vue",
    },
    {
        path: "/emailindex",
        file: "views/email/index.vue",
    },
    {
        path: "/emailsee",
        file: "views/email/see.vue",
    },
    {
        path: "/formindex",
        file: "views/form/index.vue",
    },
    {
        path: "/gridindex",
        file: "views/grid/index.vue",
    },
    {
        path: "/groupcontacter",
        file: "views/group/contacter.vue",
    },
    {
        path: "/groupindex",
        file: "views/group/index.vue",
    },
    {
        path: "/grouplist",
        file: "views/group/groupindex.vue",
    },
    {
        path: "/groupdetail",
        file: "views/group/detail.vue",
    },
    {
        path: "/groupcustomer",
        file: "views/group/customer.vue",
    },
    {
        path: "/customergroup",
        file: "views/group/groupcustomer.vue",
    },
    {
        path: "/allgroup",
        file: "views/group/allgroup.vue",
    },
    {
        path: "/groupedit",
        file: "views/group/edit.vue",
    },
    {
        path: "/canceltable",
        file: "views/group/canceltable.vue",
    },
    {
        path: "/groupnotice",
        file: "views/group/notice.vue",
    },
    {
        path: "/homepage",
        file: "views/home/homepage.vue",
    },
    {
        path: "/homemodalindex",
        file: "views/homemodal/index.vue",
    },
    {
        path: "/jujiaearly",
        file: "views/jujiabuild/early/index.vue",
    },
    {
        path: "/equipmentindex",
        file: "views/jujiabuild/equipment/index.vue",
    },
    {
        path: "/equipmentcommindex",
        file: "views/jujiabuild/equipmentcomm/index.vue",
    },
    {
        path: "/homecustomindex",
        file: "views/jujiabuild/homecustom/index.vue",
    },
    {
        path: "/institutional",
        file: "views/jujiabuild/Institutionalmanagement/index.vue",
    },
    {
        path: "/institutionalOther",
        file: "views/jujiabuild/Institutionalmanagement/indexOther.vue",
    },
    {
        path: "/institutionaluser",
        file: "views/jujiabuild/Institutionalusers/index.vue",
    },
    {
        path: "/jujiamanagement",
        file: "views/jujiabuild/management/index.vue",
    },
    {
        path: "/platformuserindex",
        file: "views/jujiabuild/platformuser/index.vue",
    },
    {
        path: "/servicelistindex",
        file: "views/jujiabuild/servicelist/index.vue",
    },
    {
        path: "/serviceorderorgplatform",
        file: "views/jujiabuild/serviceorder/org/platform.vue",
    },
    {
        path: "/serviceorderorgquest",
        file: "views/jujiabuild/serviceorder/org/quest.vue",
    },
    {
        path: "/serviceorderorglist",
        file: "views/jujiabuild/serviceorder/org/servicelist.vue",
    },
    {
        path: "/linehomeindex",
        file: "views/linehome/index.vue",
    },
    {
        path: "/memberindex",
        file: "views/member/index.vue",
    },
    {
        path: "/mobilizecontacter",
        file: "views/mobilize/contacter.vue",
    },
    {
        path: "/mobilizeindex",
        file: "views/mobilize/index.vue",
    },
    {
        path: "/modelindex",
        file: "views/model/index.vue",
    },
    {
        path: "/officialmanage",
        file: "views/official/manage.vue",
    },
    {
        path: "/officialtweet",
        file: "views/official/tweet.vue",
    },
    {
        path: "/organization",
        file: "views/organizationmanagement/organization.vue",
    },
    {
        path: "/regionaloverview",
        file: "views/overview/index.vue",
    },
    {
        path: "/partyindex",
        file: "views/party/index.vue",
    },
    {
        path: "/partyrecord",
        file: "views/party/record.vue",
    },
    {
        path: "/partystatistic",
        file: "views/party/statistic.vue",
    },
    {
        path: "/pensionindex",
        file: "views/pension/index.vue",
    },
    {
        path: "/pensionoldpeople",
        file: "views/pension/oldpeople.vue",
    },
    {
        path: "/pensionserviceoption",
        file: "views/pension/serviceoption.vue",
    },
    {
        path: "/peolistindex",
        file: "views/peolist/index.vue",
    },
    {
        path: "/peorejectindex",
        file: "views/peoreject/index.vue",
    },
    // 定向采集
    {
        path: "/questionnaireanalysis",
        file: "views/questionnaire/analysis.vue",
    },
    {
        path: "/questionnairecollect",
        file: "views/questionnaire/collect.vue",
    },
    {
        path: "/questionnaireindex",
        file: "views/questionnaire/index.vue",
    },
    {
        path: "/questionnairerecord",
        file: "views/questionnaire/questionnairerecord.vue",
    },

    // 问卷
    {
        path: "/directionalaireanalysis",
        file: "views/directional/analysis.vue",
    },
    {
        path: "/directionalairecollect",
        file: "views/directional/collect.vue",
    },
    {
        path: "/directionalireindex",
        file: "views/directional/index.vue",
    },
    {
        path: "/directionalirerecord",
        file: "views/directional/questionnairerecord.vue",
    },

    {
        path: "/regionmanage",
        file: "views/region/manage.vue",
    },
    {
        path: "/reportcustom",
        file: "views/report/custom.vue",
    },
    {
        path: "/report",
        file: "views/report/resport.vue",
    },
    {
        path: "/reportview",
        file: "views/report/view.vue",
    },
    {
        path: "/residentindex",
        file: "views/resident/index.vue",
    },
    {
        path: "/record",
        file: "views/rownumberrecord/record.vue",
    },
    {
        path: "/scenemanage",
        file: "views/scene/scenemanage.vue",
    },
    {
        path: "/spokenlangindex",
        file: "views/spokenlang/index.vue",
    },
    {
        path: "/spokenlangmanage",
        file: "views/spokenlang/manage.vue",
    },
    {
        path: "/sponsoranward",
        file: "views/sponsor/award.vue",
    },
    {
        path: "/sponsorcontactuser",
        file: "views/sponsor/contactuser.vue",
    },
    {
        path: "/sponsorindex",
        file: "views/sponsor/index.vue",
    },
    {
        path: "/sponsorservices",
        file: "views/sponsor/services.vue",
    },
    {
        path: "/sponsorshopmanage",
        file: "views/sponsor/shopmanage.vue",
    },
    {
        path: "/statisticsactivities",
        file: "views/statistics/activities.vue",
    },
    {
        path: "/statisticscontent",
        file: "views/statistics/content.vue",
    },
    {
        path: "/statisticsintegral",
        file: "views/statistics/integral.vue",
    },
    {
        path: "/statisticsmenu",
        file: "views/statistics/menu.vue",
    },
    {
        path: "/statisticsmobilize",
        file: "views/statistics/mobilize.vue",
    },
    {
        path: "/statisticsthirdresource",
        file: "views/statistics/thirdresource.vue",
    },
    {
        path: "/statisticsuser",
        file: "views/statistics/user.vue",
    },
    {
        path: "/statisticsuserother",
        file: "views/statistics/userother.vue",
    },
    {
        path: "/statisticsuserregister",
        file: "views/statistics/userregister.vue",
    },
    {
        path: "/userscount",
        file: "views/statistics/userscount.vue",
    },
    {
        path: "/statisticsvirus19",
        file: "views/statistics/virus19.vue",
    },
    {
        path: "/statisticsvirus19ranking",
        file: "views/statistics/virus19ranking.vue",
    },
    {
        path: "/suiyuestatisticsactivity",
        file: "views/suiyuestatistics/activity.vue",
    },
    {
        path: "/suiyuestatisticsarchives",
        file: "views/suiyuestatistics/archives.vue",
    },
    {
        path: "/suiyuestatisticsmobilize",
        file: "views/suiyuestatistics/mobilize.vue",
    },
    {
        path: "/suiyuestatisticsresident",
        file: "views/suiyuestatistics/resident.vue",
    },
    {
        path: "/system",
        file: "views/systemmanagement/system.vue",
    },
    {
        path: "/tagindex",
        file: "views/tag/index.vue",
    },
    {
        path: "/testanchor",
        file: "views/test/Anchor.vue",
    },
    {
        path: "/testcascader",
        file: "views/test/Cascader.vue",
    },
    {
        path: "/testcropper",
        file: "views/test/Cropper.vue",
    },
    {
        path: "/testscroll",
        file: "views/test/Scroll.vue",
    },
    {
        path: "/verified",
        file: "views/verified/verified.vue",
    },
    {
        path: "/voteindex",
        file: "views/vote/index.vue",
    },
    {
        path: "/votemanage",
        file: "views/vote/manage.vue",
    },
    {
        path: "/voterecord",
        file: "views/vote/record.vue",
    },
    {
        path: "/voterecordother",
        file: "views/vote/recordother.vue",
    },
    {
        path: "/supplement",
        file: "views/linehome/supplement.vue",
    },
    {
        path: "/custompage",
        file: "views/custompage/manage.vue",
    },
    {
        path: "/pccustompage",
        file: "views/custompage/pcmanage.vue",
    },
    {
        path: "/linehomeaddform",
        file: "views/linehome/childrens/addForm.vue",
    },
    {
        path: "/linehomesetform",
        file: "views/linehome/childrens/setForm.vue",
    },
    {
        path: "/synchronization",
        file: "views/linehome/synchronization.vue",
    },
    {
        path: "/smsrecordindex",
        file: "views/smsrecord/index.vue",
    },
    {
        path: "/mobilizationindex",
        file: "views/mobilization/index.vue",
    },
    {
        path: "/leaderindex",
        file: "views/grid/leaderindex.vue",
    },
    {
        path: "/shopmanagementindex",
        file: "views/shopmanagement/index.vue",
    },
    {
        path: "/usergrowth",
        file: "views/statistics/usergrowth.vue",
    },
    {
        path: "/communitychart",
        file: "views/statistics/community.vue",
    },
    {
        path: "/mobilizationsystem",
        file: "views/mobilizationsystem/index.vue",
    },
    {
        path: "/importmessage",
        file: "views/mobilizationsystem/importmessage.vue",
    },
    {
        path: "/initialimportmessage",
        file: "views/mobilizationsystem/initialimportmessage.vue",
    },
    // 网格小组
    {
        path: "/gridgroupindex",
        file: "views/mobilizationsystem/childpage/gridgroup.vue",
    },

    // 网格党小组初始化
    {
        path: "/partyinitialization",
        file: "views/mobilizationsystem/childpage/partyinitialization",
    },

    // 网格党支部
    {
        path: "/gridpartyindex",
        file: "views/mobilizationsystem/childpage/gridparty.vue",
    },
    // 网格党支部初始化
    {
        path: "/gridinitialization",
        file: "views/mobilizationsystem/childpage/gridinitialization.vue",
    },
    // 居民数据初始化
    {
        path: "/communityinitiali",
        file: "views/mobilizationsystem/childpage/communityinitiali.vue",
    },
    // 居民数据
    {
        path: "/residentchildindex",
        file: "views/mobilizationsystem/childpage/resident.vue",
    },
    // 民情档案数据初始化
    {
        path: "/civilinitialization",
        file: "views/mobilizationsystem/childpage/civilinitialization.vue",
    },
    {
        path: "/mobilizationsynchronized",
        file: "views/mobilization/synchronized.vue",
    },
    {
        path: "/mobilizationregistered",
        file: "views/mobilization/registered.vue",
    },
    {
        path: "/verifiedoverview",
        file: "views/linehome/overview.vue",
    },
    {
        path: "/linehomepage",
        file: "views/linehome/homepage.vue",
    },
    {
        path: "/shopconfirmed",
        file: "views/shopmanagement/childrens/confirmed.vue",
    },
    {
        path: "/linehomeconfirmed",
        file: "views/linehome/childrens/confirmed.vue",
    },
    {
        path: "/linehomemanage",
        file: "views/linehomemanage/index.vue",
    },
    {
        path: "/verifiedsetindex",
        file: "views/verified/setindex.vue",
    },
    {
        path: "/nationlset",
        file: "views/verified/nationalset.vue",
    },
    {
        path: "/logindex",
        file: "views/logmanager/index.vue",
    },
    {
        path: "/allIndex",
        file: "views/information/allIndex/index.vue",
    },
    {
        path: "/pushTable",
        file: "views/information/pushTable.vue",
    },
    {
        path: "/houseingarrairsindex",
        file: "views/information/housingaffairs/index.vue",
    },
    {
        path: "/newhousingaffairs",
        file: "views/information/newhousingaffairs/index.vue",
    },
    {
        path: "/newanswerindex",
        file: "views/information/newanswer/index.vue",
    },
    {
        path: "/templateindex",
        file: "views/information/template/index.vue",
    },
    {
        path: "/columnconfigindex",
        file: "views/authCloud/column/index.vue",
    },
    {
        path: "/columnconfigtreeindex",
        file: "views/authCloud/column/treeindex.vue",
    },
    {
        path: "/shopindex",
        file: "views/accessoryrental/shop/index.vue",
    },

    {
        path: "/leaserecord",
        file: "views/leaserecord/index.vue",
    },
    {
        path: "/accessoryorder",
        file: "views/accessoryrental/order/index.vue",
    },
    {
        path: "/publishset",
        file: "views/publishset/index.vue",
    },
    {
        path: "/test",
        file: "views/test/test.vue",
    },
    {
        path: "/authCloudTenant",
        file: "views/authCloud/tenant/index.vue",
    },
    {
        path: "/authCloudSystem",
        file: "views/authCloud/system/index.vue",
    },
    {
        path: "/openmsm",
        file: "views/openmsm/index.vue",
    },
    {
        path: "/blank",
        file: "views/linehome/childrens/blank.vue",
    },
    {
        path: "/userspread",
        file: "views/portalSystem/userspread/index.vue",
    },
    {
        path: "/authCloudSonSystem",
        file: "views/authCloud/sonSystem/index.vue",
    },
    {
        path: "/streetUsage",
        file: "views/portalSystem/streetUsage/index.vue",
    },
    {
        path: "/portal",
        file: "views/portalSystem/portal/index.vue",
    },
    {
        path: "/buildprogress",
        file: "views/portalSystem/buildprogress/index.vue",
    },
    {
        path: "/columnBuild",
        file: "views/portalSystem/columnBuild/index.vue",
    },
    {
        path: "/authCloudMenu",
        file: "views/authCloud/menu/index.vue",
    },
    {
        path: "/companysituation",
        file: "views/portalSystem/company/index.vue",
    },
    {
        path: "/readunread",
        file: "views/readunread/index.vue",
    },
    {
        path: "/zoning",
        file: "views/zoning/index.vue",
    },
    {
        path: "/smsrate",
        file: "views/smsrate/index.vue",
    },
    {
        path: "/authCloudSystemFunction",
        file: "views/authCloud/systemFunction/index.vue",
    },
    {
        path: "/columnuse",
        file: "views/portalSystem/columnuse/index.vue",
    },
    {
        path: "/authCloudRole",
        file: "views/authCloud/role/index.vue",
    },
    {
        path: "/columnstatistics",
        file: "views/columnstatistics/index.vue",
    },
    {
        //
        path: "/appletsurvey",
        file: "views/appletstatistics/survey.vue",
    },
    {
        path: "/accesstrends",
        file: "views/appletstatistics/accesstrends.vue",
    },
    {
        path: "/accessspread",
        file: "views/appletstatistics/accessspread.vue",
    },
    {
        path: "/accesskeep",
        file: "views/appletstatistics/accesskeep.vue",
    },
    {
        path: "/accesspage",
        file: "views/appletstatistics/accesspage.vue",
    },
    {
        path: "/userportrait",
        file: "views/appletstatistics/userportrait.vue",
    },
    {
        path: "/savedata",
        file: "views/appletstatistics/savedata.vue",
    },
    {
        path: "/graphiccategory",
        file: "views/information/graphiccategory/index.vue",
    },
    {
        path: "/mapclass",
        file: "views/information/mapclass/index.vue",
    },
    {
        path: "/warrantsystem",
        file: "views/authCloud/system/warrant.vue",
    },
    {
        path: "/wordsmanage",
        file: "views/wordsmanage/index.vue",
    },
    {
        path: "/wordssortmanage",
        file: "views/wordsmanage/sortmanage.vue",
    },
    {
        path: "/communityrecord",
        file: "views/communityrecord/index.vue",
    },
    {
        path: "/operationindex",
        file: "views/portalSystem/operation/index.vue",
    },
    {
        path: "/accountopening",
        file: "views/portalSystem/accountopening/index.vue",
    },
    {
        path: "/weappHome",
        file: "views/weappHome/table.vue",
    },
    {
        path: "/nucleicacidoverview",
        file: "views/nucleicacidoverview/index.vue",
    },
    {
        path: "/warrantsystem",
        file: "views/authCloud/system/warrant.vue",
    },
    {
        path: "/sonsystemorg",
        file: "views/authCloud/sonSystemOrg/index.vue",
    },
    {
        path: "/dimensionmanage",
        file: "views/authCloud/sonSystemOrg/dimension.vue",
    },

    {
        path: "/jointly/info",
        file: "views/jointly/info/table.vue",
    },
    {
        path: "/jointly/record",
        file: "views/jointly/info/record.vue",
    },
    {
        path: "/jointly/recordother",
        file: "views/jointly/info/recordother.vue",
    },
    {
        path: "/jointly/label",
        file: "views/jointly/label/table.vue",
    },
    {
        path: "/jointly/alllabel",
        file: "views/jointly/label/alltable.vue",
    },
    {
        path: "/jointly/zilabel",
        file: "views/jointly/label/zitable.vue",
    },
    {
        path: "/jointly/label/filter",
        file: "views/jointly/label/filter/table.vue",
    },
    {
        path: "/jointly/type",
        file: "views/jointly/type/table.vue",
    },
    {
        path: "/staffmanage",
        file: "views/authCloud/staff/index.vue",
    },
    {
        path: "/templatemanage",
        file: "views/templatemanage/index.vue",
    },
    {
        path: "/templatedetail",
        file: "views/templatemanage/detail.vue",
    },
    {
        path: "/querycommunity",
        file: "views/querycommunity/index.vue",
    },
    {
        path: "/warrantorgson",
        file: "views/authCloud/sonSystemOrg/warrantson.vue",
    },
    {
        path: "/appletcolumn",
        file: "views/appletcolumn/appletcolumn.vue",
    },
    {
        path: "/accesstrendscopy",
        file: "views/appletstatisticscopy/accesstrends.vue",
    },
    {
        path: "/appletsurveycopy",
        file: "views/appletstatisticscopy/survey.vue",
    },
    {
        path: "/informationoplog",
        file: "views/informationoplog/index.vue",
    },
    // 商城配货
    {
        path: "/distribution_inventory",
        file: "views/shop/distribution/inventory/table.vue",
    },
    // 商城配货
    {
        path: "/mentionmanage",
        file: "views/shop/distribution/manage/table.vue",
    },
    {
        path: "/platformstatisticshome",
        file: "views/platformstatistics/home/index.vue",
    },
    // 商城配货汇总
    {
        path: "/distribution_summary",
        file: "views/shop/distribution/summary/table.vue",
    },
    {
        path: "/authcloudorgmenu",
        file: "views/authCloud/orgmenu/index.vue",
    },
    {
        path: "/authcloudorgstaff",
        file: "views/authCloud/orgstaff/index.vue",
    },
    {
        path: "/authcloudorgrole",
        file: "views/authCloud/orgrole/index.vue",
    },
    {
        path: "/test15minmanage",
        file: "views/test15min/test15minmanage.vue",
    },
    // 电动自行车充电桩
    {
        path: "/chargingstation",
        file: "views/test15min/chargingstation.vue",
    },
    // 随约打卡服务
    {
        path: "/clockin",
        file: "views/test15min/clockin.vue",
    },
    // 便民服务
    {
        path: "/convenient",
        file: "views/test15min/convenient.vue",
    },
    {
        path: "/convenientrecord",
        file: "views/test15min/record.vue",
    },
    // 任务包--答题活动
    {
        path: "/taskpackgeindex",
        file: "views/test15min/taskpackge.vue",
    },
    // 任务包-答题记录
    {
        path: "/taskpackgeindexrecord",
        file: "views/test15min/taskpackgerecord.vue",
    },
    // 任务包-中奖名单
    {
        path: "/taskpackgeindexprice",
        file: "views/test15min/taskpackgeprice.vue",
    },
    {
        path: "/importmessagelw",
        file: "views/mobilizationsystemlw/importmessage.vue",
    },
    {
        path: "/initialimportmessagelw",
        file: "views/mobilizationsystemlw/initialimportmessage.vue",
    },
    // 网格小组
    {
        path: "/gridgroupindexlw",
        file: "views/mobilizationsystemlw/childpage/gridgroup.vue",
    },

    // 网格党小组初始化
    {
        path: "/partyinitializationlw",
        file: "views/mobilizationsystemlw/childpage/partyinitialization",
    },

    // 网格党支部
    {
        path: "/gridpartyindexlw",
        file: "views/mobilizationsystemlw/childpage/gridparty.vue",
    },
    // 网格党支部初始化
    {
        path: "/gridinitializationlw",
        file: "views/mobilizationsystemlw/childpage/gridinitialization.vue",
    },
    // 居民数据初始化
    {
        path: "/communityinitialilw",
        file: "views/mobilizationsystemlw/childpage/communityinitiali.vue",
    },
    // 居民数据
    {
        path: "/residentchildindexlw",
        file: "views/mobilizationsystemlw/childpage/resident.vue",
    },
    // 民情档案数据初始化
    {
        path: "/civilinitializationlw",
        file: "views/mobilizationsystemlw/childpage/civilinitialization.vue",
    },
    // 商城配货
    {
        path: "/distribution_inventory",
        file: "views/shop/distribution/inventory/table.vue",
    },
    {
        path: "/platformstatisticshome",
        file: "views/platformstatistics/home/index.vue",
    },
    // 商城配货汇总
    {
        path: "/distribution_summary",
        file: "views/shop/distribution/summary/table.vue",
    },
    // 供应商营业报表汇总
    {
        path: "/report_business",
        file: "views/shop/report/business/table.vue",
    },
    // 供应商商品销售报表
    {
        path: "/report_commodity",
        file: "views/shop/report/commodity/table.vue",
    },
    // 终端群组营业报表
    {
        path: "/report_terminal",
        file: "views/shop/report/terminal/table.vue",
    },
    // 终端机管理
    {
        path: "/terminal_manage",
        file: "views/shop/terminal/manage/table.vue",
    },
    // 终端机运行状态
    {
        path: "/terminal_status",
        file: "views/shop/terminal/status/table.vue",
    },
    // 终端机群组管理
    {
        path: "/terminal_group",
        file: "views/shop/terminal/group/table.vue",
    },
    // 终端机群组管理2
    {
        path: "/terminal_groupothertable",
        file: "views/shop/terminal/group/other.vue",
    },
    // 运营商管理
    {
        path: "/terminal_groupother",
        file: "views/shop/terminal/group/tableother.vue",
    },
    // 获取绑定的供应商列表
    {
        path: "/terminal_groupcompany",
        file: "views/shop/terminal/group/company.vue",
    },
    // 终端机热销品管理
    {
        path: "/terminal_hot",
        file: "views/shop/terminal/hot/table.vue",
    },
    // 商城商品管理
    {
        path: "/goods",
        file: "views/shop/goods/table.vue",
    },
    // 商城商品管理 - 平台
    {
        path: "/goodsplatform",
        file: "views/shop/goods/platform.vue",
    },
    // 商城分类管理
    {
        path: "/goods_classify",
        file: "views/shop/classify/table.vue",
    },
    // 商城出库管理
    {
        path: "/outstock",
        file: "views/shop/outstock/index.vue",
    },
    // 商城看板
    {
        path: "/shopboard",
        file: "views/shop/board/index.vue",
    },
    {
        path: "/orgmenupublish",
        file: "views/orgmenupublish/index.vue",
    },
    {
        path: "/testpage",
        file: "views/testpage/index.vue",
    },
    {
        path: "/orgmenupublish_data",
        file: "views/orgmenupublish/data.vue",
    },

    //民政局统计
    // 首页
    {
        path: "/cabstatisticsindex",
        file: "views/cabstatistics/home/index.vue",
    },

    // 日访问人数（日活）
    {
        path: "/cabvisitpersons",
        file: "views/cabstatistics/platformdata/visitpersons.vue",
    },
    // 平均日活
    {
        path: "/cabaveragepersons",
        file: "views/cabstatistics/platformdata/averagepersons.vue",
    },
    // 日访问次数
    {
        path: "/cabvisitnum",
        file: "views/cabstatistics/platformdata/visitnum.vue",
    },
    // 平均访问次数
    {
        path: "/cabaveragenum",
        file: "views/cabstatistics/platformdata/averagenum.vue",
    },

    // 用户分布
    {
        path: "/cabuserspread",
        file: "views/cabstatistics/userdata/userspread.vue",
    },
    // 数据趋势
    {
        path: "/cabdatatrend",
        file: "views/cabstatistics/userdata/datatrend.vue",
    },
    //同比增长率
    {
        path: "/cabrateofrise",
        file: "views/cabstatistics/userdata/rateofrise.vue",
    },

    //社区统计
    {
        path: "/cabcommunitystatistics",
        file: "views/cabstatistics/communitystatistics/communitystatistics.vue",
    },
    //分布情况
    {
        path: "/cabdistribution",
        file: "views/cabstatistics/communitystatistics/distribution.vue",
    },

    //内容发布统计
    {
        path: "/cabcontentstatistics",
        file: "views/cabstatistics/releasestatistics/contentstatistics.vue",
    },
    // 社区发布排行
    {
        path: "/cabcommunityranking",
        file: "views/cabstatistics/releasestatistics/communityranking.vue",
    },
    // 账号注销管理
    {
        path: "/userlogoff",
        file: "views/userlogoff/index.vue",
    },
    // 平台菜单管理授权机构
    {
        path: "/authorizenewindex",
        file: "views/authorize/newIndex.vue",
    },
    // 居民数据初始化 转换原始数据
    {
        path: "/switchresidentdata",
        file: "views/mobilizationsystem/childpage/switchdata.vue",
    },
    //匹配转换
    {
        path: "/matchingresidentdata",
        file: "views/mobilizationsystem/childpage/matchingdata.vue",
    },
    // lw居民数据初始化 转换原始数据
    {
        path: "/switchresidentdatalw",
        file: "views/mobilizationsystemlw/childpage/switchdata.vue",
    },
    //匹配转换
    {
        path: "/matchingresidentdatalw",
        file: "views/mobilizationsystemlw/childpage/matchingdata.vue",
    },
    //直播间管理
    {
        path: "/liveroommanage",
        file: "views/information/liveroom/index.vue",
    },
    //社区机构坐标库
    {
        path: "/orgCoordinatemanage",
        file: "views/area/orgCoordinate.vue",
    },
    //脱敏日志
    {
        path: "/desensitization",
        file: "views/userlogoff/desensitization.vue",
    },
    // 英文版实名认证
    {
        path: "/alienmanage",
        file: "views/verified/alienmanage",
    },
    //今日发布
    {
        path: "/releasedtoday",
        file: "views/information/releasedtoday",
    },
    //栏目情况统计2
    {
        path: "/columnstatisticssecond",
        file: "views/columnstatistics/secondindex.vue",
    },
    // 居民基础数据库
    {
        path: "/residentdatabase",
        file: "views/residentdatabase/index.vue",
    },
    // 居民基础数据库
    {
        path: "/residentdatabasetable",
        file: "views/residentdatabase/table.vue",
    },
    // 居民状态更新
    {
        path: "/residentupdatastatus",
        file: "views/residentdatabase/updatastatus.vue",
    },
    //栏目情况统计3
    {
        path: "/columnstatisticsthird",
        file: "views/columnstatistics/thirdindex.vue",
    },
    //定制功能或报表
    {
        path: "/customreport",
        file: "views/residentdatabase/customreport.vue",
    },
    //定制列表管理
    {
        path: "/reportdatamanage",
        file: "views/residentdatabase/reportdatamanage.vue",
    },
    // 居民基础数据库 - 我的定制功能或报表
    {
        path: "/residentdatabasemybusiness",
        file: "views/residentdatabase/my_business.vue",
    },
    // 居民基础数据库 - 自定义固定业务
    {
        path: "/residentdatabasefixedbusiness",
        file: "views/residentdatabase/fixed_business.vue",
    },
    // 居民基础数据库 - 数据
    {
        path: "/residentdatabasedatatable",
        file: "views/residentdatabase/data/table.vue",
    },
    {
        path: "/actuatormanage",
        file: "views/verified/actuatormanage.vue",
    },
    //栏目情况统计4
    {
        path: "/columnstatisticsfourth",
        file: "views/columnstatistics/fourthindex.vue",
    },
    {
        path: "/15minutesindex",
        file: "views/columnstatistics/15minutesindex.vue",
    },
    {
        path: "/onlinesignature",
        file: "views/columnstatistics/onlinesignature.vue",
    },
    {
        path: "/shouldhave",
        file: "views/columnstatistics/shouldhave.vue",
    },
    {
        path: "/shouldhaveother",
        file: "views/columnstatistics/shouldhaveother.vue",
    },
    {
        path: "/institutionsindex",
        file: "views/columnstatistics/institutionsindex.vue",
    },
    {
        path: "/zonebehaviordata",
        file: "views/columnstatistics/zonebehaviordata.vue",
    },
    {
        path: "/orgbehaviordata",
        file: "views/columnstatistics/orgbehaviordata.vue",
    },
    {
        path: "/aftertraining",
        file: "views/columnstatistics/aftertraining.vue",
    },
    {
        path: "/firststatistics",
        file: "views/columnstatistics/firststatistics.vue",
    },
    {
        path: "/firststatisticsdetail",
        file: "views/columnstatistics/firststatisticsdetail.vue",
    },
    {
        path: "/committeestatistics",
        file: "views/columnstatistics/committeestatistics.vue",
    },
    {
        path: "/residentread",
        file: "views/residentread/index.vue",
    },
    {
        path: "/residentorgindex",
        file: "views/residentread/orgindex.vue",
    },
    // 体质检测
    {
        path: "/constitutiontestrecord",
        file: "views/constitutiontest/record/index",
    },
    // 运营周报
    {
        path: "/operationreportindex",
        file: "views/operationreport/index",
    },
    // 表单明细
    {
        path: "/operationreportdetail",
        file: "views/operationreport/detail",
    },
    // 表格详情
    {
        path: "/operationreportform",
        file: "views/operationreport/form",
    },
    // 空白栏目
    {
        path: "/blankcolumnindex",
        file: "views/blankcolumn/index",
    },
    {
        path: "/blankcolumnother",
        file: "views/blankcolumnother/index",
    },
    {
        path: "/blankcolumnotherdetail",
        file: "views/blankcolumnother/detail",
    },
    {
        path: "/blankcolumndetail",
        file: "views/blankcolumn/detail",
    },
    // 签名模板管理
    {
        path: "/autographindex",
        file: "views/autograph/index",
    },
    // 签名模板-签署记录
    {
        path: "/autographdetial",
        file: "views/autograph/detail",
    },
    // 表单采集同步
    {
        path: "/formcollectionsynctable",
        file: "views/formcollectionsync/table",
    },

    // 运营管理界面
    {
        path: "/managsystemindex",
        file: "views/managsystem/index",
    },

    // 街道管理 - 内容运营情况 - 累计
    {
        path: "/managsystemoperationcontent",
        file: "views/managsystem/operationcontent",
    },

    // 街道管理 - 内容运营情况 - 日增
    {
        path: "/managsystemoperationcontentday",
        file: "views/managsystem/operationcontentday",
    },

    // 街道管理 - 任务跟踪
    {
        path: "/managsystemtasktracking",
        file: "views/managsystem/tasktracking",
    },

    // 街道管理 - 各社区注册率排行
    {
        path: "/managsystemregistrationranking",
        file: "views/managsystem/registrationranking",
    },

    // 街道管理 - 用户数据趋势
    {
        path: "/managsystemuserdata",
        file: "views/managsystem/userdata",
    },

    // 街道管理 - 用户分布
    {
        path: "/managsystemuserprofile",
        file: "views/managsystem/userprofile",
    },

    // 街道管理 - 统计
    {
        path: "/managsystemstatistics",
        file: "views/managsystem/statistics",
    },

    // 社区动员体系 - 首页
    {
        path: "/communitymobilization",
        file: "views/communitymobilization/index",
    },

    // 社区动员体系 - 新增架构
    {
        path: "/communitymobilizationeditframework",
        file: "views/communitymobilization/editframework",
    },

    // 社区动员体系 - 画布
    {
        path: "/communitymobilizationdistribution",
        file: "views/communitymobilization/distribution",
    },

    // 社区动员体系 - 成员管理
    {
        path: "/communitymobilizationmember",
        file: "views/communitymobilization/member",
    },

    // 社区动员体系 - 岗位管理
    {
        path: "/communitymobilizationpost",
        file: "views/communitymobilization/post",
    },
    // 社区动员体系 - 架构线搭建
    {
        path: "/communitymobilizationdefinition",
        file: "views/communitymobilization/definition",
    },
    // 电子周报 - 首页
    {
        path: "/weeklyreports",
        file: "views/weeklyreports/index",
    },

    // 电子周报 - 审核表格
    {
        path: "/weeklyreportsaudittable",
        file: "views/weeklyreports/audit_table",
    },

    // 电子周报 - 审核详情
    {
        path: "/weeklyreportsaudit",
        file: "views/weeklyreports/audit",
    },

    // 电子周报 - 查阅情况
    {
        path: "/weeklyreportsread",
        file: "views/weeklyreports/read",
    },

    // 电子周报 - 查阅详情
    {
        path: "/weeklyreportsreaddetail",
        file: "views/weeklyreports/read_detail",
    },

    // 电子周报 - 查阅统计
    {
        path: "/weeklyreportsreadstats",
        file: "views/weeklyreports/read_stats",
    },

    // 电子周报 - 操作记录
    {
        path: "/weeklyreportsoperate",
        file: "views/weeklyreports/operate",
    },
    // 操作说明
    {
        path: "/operatinginstrindex",
        file: "views/operatinginstr/index",
    },
    // 栏目提醒 - 首页
    {
        path: "/columnreminder",
        file: "views/columnreminder/index",
    },

    // 栏目提醒 - 提醒人
    {
        path: "/columnreminderpeople",
        file: "views/columnreminder/people",
    },

    // 栏目提醒 - 按栏目
    {
        path: "/columnremindercolumn",
        file: "views/columnreminder/column",
    },
    // 设备升级
    {
        path: "/deviceupgrade",
        file: "views/deviceupgrade/index",
    },
    // 管理员列表
    {
        path: "/deviceupgradetable",
        file: "views/deviceupgrade/table",
    },
    // 管理员列表
    {
        path: "/deviceupgradedetail",
        file: "views/deviceupgrade/detail",
    },
    // 街镇居民 - 首页
    {
        path: "/residentdatabasestreet",
        file: "views/residentdatabase/street/index",
    },

    // 街镇居民 - 首页
    {
        path: "/residentdatabasestreetexport",
        file: "views/residentdatabase/street/export",
    },

    // 街镇居民 - 数据统计
    {
        path: "/residentdatabasestreetstatistics",
        file: "views/residentdatabase/street/statistics",
    },

    // 评价系统 - 管理
    {
        path: "/commentmanage",
        file: "views/comment/manage",
    },
    // 授权机构统计
    {
        path: "/authorized",
        file: "views/columnstatistics/authorized",
    },
    // 微信群管理
    {
        path: "/wechatgroup",
        file: "views/wechatgroup/index",
    },
    {
        path: "/wechatgrouporgindex",
        file: "views/wechatgroup/orgindex",
    },
    {
        path: "/wechatgroupdetail",
        file: "views/wechatgroup/detail",
    },
    {
        path: "/wechatgroupchart",
        file: "views/wechatgroup/chart",
    },
    // 商家机构黑名单
    {
        path: "/shopconceal",
        file: "views/jointly/conceal/table",
    },
    // 导购分类
    {
        path: "/guidecategory",
        file: "views/jointly/guide/category/table",
    },
    // 导购分类 - 一级分类
    {
        path: "/guidecategoryprimary",
        file: "views/jointly/guide/category/primary",
    },
    // 二维码生成
    {
        path: "/qrcodeindex",
        file: "views/qrcode/index",
    },
    // 供应链选品 - 分组
    {
        path: "/shopselectiongroup",
        file: "views/shop/selection/group/table",
    },
    // 供应链选品 - 分组商品
    {
        path: "/shopselectiongroupgoods",
        file: "views/shop/selection/group/goods",
    },
    // 供应链选品 - 选择商品
    {
        path: "/shopselectiongoods",
        file: "views/shop/selection/goods/index",
    },
    // 快速定制功能 - 表格
    {
        path: "/fastcustomization",
        file: "views/fastcustomization/table",
    },
    // 快速定制功能 - 已授权机构
    {
        path: "/fastcustomizationauthorized",
        file: "views/fastcustomization/authorized",
    },
    // 逢源任务包
    {
        path: "/taskpackge",
        file: "views/taskpackge/index",
    },
    // 任务包浏览记录
    {
        path: "/taskpackgerecord",
        file: "views/taskpackge/record",
    },
    // 瑞承公益创投 - 上门巡访
    {
        path: "/benefitvisit",
        file: "views/benefit/visit",
    },
    // 瑞承公益创投 - 健康指导服务
    {
        path: "/benefit",
        file: "views/benefit/table",
    },
    // 瑞承公益创投 - 健康指导分类管理
    {
        path: "/benefitcat",
        file: "views/benefit/cat/table",
    },
    // 弹窗设置界面 - 旧界面跳转
    {
        path: "/setorgmodal",
        file: "views/framemodal/setorgmodal",
    },
    // 弹窗设置界面 - 授权社区
    {
        path: "/setorgmodalorg",
        file: "views/framemodal/setorgmodalorg",
    },
    // 统计数据 - 52个示范社区发布数
    {
        path: "/statisticalreleases",
        file: "views/statisticaldata/releases",
    },
    // 统计数据 - 广州市52个示范社区服务情况
    {
        path: "/statisticalsituation",
        file: "views/statisticaldata/situation",
    },
    {
        path: "/statisticalsituationorg",
        file: "views/statisticaldata/situationorg",
    },
    // 统计数据 - 广州市52个示范社区服务情况
    {
        path: "/statisticaltable",
        file: "views/statisticaldata/situationtable",
    },
    // 统计数据 - 广州市52个示范社区服务情况
    {
        path: "/situationdetail",
        file: "views/statisticaldata/situationdetail",
    },
    // 统计数据 - 广州市52个示范社区服务情况 - 报表切换
    {
        path: "/statisticalgrid",
        file: "views/statisticaldata/grid",
    },
    // 统计数据 - 答题结果统计
    {
        path: "/statisticalanswer",
        file: "views/statisticaldata/answer",
    },
    //  统计数据 - 逢源任务包统计数据
    {
        path: "/taskstatic",
        file: "views/statisticaldata/taskstatic",
    },
    // 社区议事厅
    {
        path: "/agendaroom",
        file: "views/agendaroom/index",
    },
    // 社区议事厅 - 进度
    {
        path: "/agendaroomevolve",
        file: "views/agendaroom/evolve",
    },
    // 社区议事厅 - 评价管理
    {
        path: "/agendaroomcomment",
        file: "views/agendaroom/comment",
    },
    // 社区动态 - 评价管理
    {
        path: "/orgcomment",
        file: "views/agendaroom/orgcomment",
    },
    // 社区议事厅 - 意见收集
    {
        path: "/agendaroomopinion",
        file: "views/agendaroom/opinion",
    },
    // 社区议事厅 - 意见详情
    {
        path: "/agendaroomdetail",
        file: "views/agendaroom/detail",
    },
    // 社区议事厅 - 已归档
    {
        path: "/agendaroomfinish",
        file: "views/agendaroom/finish",
    },
    // 社区议事厅 - 线下
    {
        path: "/agendaroomoffline",
        file: "views/agendaroom/offline/table",
    },
    // 社区议事厅 - 线下详情
    {
        path: "/agendaroomofflinedetail",
        file: "views/agendaroom/offline/detail",
    },
    // 居民基础数据库 - 建设统计
    {
        path: "/residentdatabasestatisticsconstruction",
        file: "views/residentdatabase/statistics/construction",
    },
    // 居民基础数据库 - 用户统计
    {
        path: "/residentdatabasestatisticsuser",
        file: "views/residentdatabase/statistics/user",
    },
    {
        path: "/messagebatchlist",
        file: "views/authCloud/message/batch/batchlist.vue",
    },

    {
        path: "/messagebatchdetail",
        file: "views/authCloud/message/batch/batchdetail.vue",
    },
    // 直播列表
    {
        path: "/liveindex",
        file: "views/live/index",
    },
    // 第三方引流统计
    {
        path: "/thirdparty",
        file: "views/thirdparty/index",
    },
    // 第三方引流统计 - 设置第三方
    {
        path: "/thirdpartytable",
        file: "views/thirdparty/table",
    },
    // 第三方引流统计 - 业务点
    {
        path: "/thirdpartyitem",
        file: "views/thirdparty/item",
    },
    // 第三方引流统计 - 统计分析
    {
        path: "/thirdpartystatistics",
        file: "views/thirdparty/statistics",
    },
    // 应读已读
    {
        path: "/statisticsread",
        file: "views/statistics/read/table",
    },
    // 应读已读 - 详情
    {
        path: "/statisticsreaddetail",
        file: "views/statistics/read/detail",
    },
    // 功能应用
    {
        path: "/application",
        file: "views/application/table",
    },
    // 街镇内容发布
    {
        path: "/streetstatisticspublish",
        file: "views/street/statistics/publish",
    },
    // 数据报表
    {
        path: "/datareport",
        file: "views/datareport/table",
    },
    // 机构首页设置
    {
        path: "/organizationhome",
        file: "views/organization/home/table",
    },
    // 机构标签设置
    {
        path: "/organizationlabel",
        file: "views/organization/label/table",
    },
    // 机构在线指标
    {
        path: "/statisticsorgonlineindex",
        file: "views/statistics/orgonlineindex/table",
    },
    // 信息阅读量管理
    {
        path: "/informationread",
        file: "views/information/read/table",
    },
    // 信息阅读量管理
    {
        path: "/informationread",
        file: "views/information/read/table",
    },
    // 栏目更新检测结果
    {
        path: "/columnreminderdetection",
        file: "views/columnreminder/detection/index",
    },
    // 栏目更新检测结果 - 机构详情
    {
        path: "/columnreminderdetectionorgdetail",
        file: "views/columnreminder/detection/orgdetail",
    },
    // 栏目更新检测结果 - 栏目详情
    {
        path: "/columnreminderdetectioncolumndetail",
        file: "views/columnreminder/detection/columndetail",
    },
    // 栏目提醒设置
    {
        path: "/columnremindersettingtable",
        file: "views/columnreminder/setting/table",
    },
    // 栏目提醒设置
    {
        path: "/columnremindersetting",
        file: "views/columnreminder/setting/setting",
    },
    // 社区生态商城模块 - 首页
    {
        path: "/mallhomepage",
        file: "views/CommunityMall/homepage/index",
    },
    // 社区生态商城模块 - 积分规则
    {
        path: "/mallintegralrule",
        file: "views/CommunityMall/integralrule/index",
    },
    // 社区生态商城模块 - 积分模式
    {
        path: "/mallintegrationmode",
        file: "views/CommunityMall/integrationmode/index",
    },
    // 社区生态商城模块 - 积分商城
    {
        path: "/mallpointsmall",
        file: "views/CommunityMall/pointsmall/index",
    },
    // 社区生态商城模块 - 业务点定义
    {
        path: "/pointdefinition",
        file: "views/CommunityMall/pointdefinition/index",
    },
    {
        path: "/orgintegralrule",
        file: "views/CommunityMall/orgintegralrule/index",
    },
    {
        path: "/orgintegralruleuser",
        file: "views/CommunityMall/orgintegralrule/user",
    },
    // 社区生态商城模块 - 用户排行版
    {
        path: "/userranking",
        file: "views/CommunityMall/rankingboard/userranking",
    },
    {
        path: "/rulepage",
        file: "views/CommunityMall/rulepage/index",
    },
    // 指标列表
    {
        path: "/informationContracti",
        file: "views/contract/Index",
    },
    // 指标列表
    {
        path: "/informationDetail",
        file: "views/contract/detail",
    },
    // 社区生态商城分类
    {
        path: "/shopclassifyecology",
        file: "views/shop/classify_ecology/table",
    },
    // 社区生态商城分类 - 商家管理
    {
        path: "/shopclassifyecologycompany",
        file: "views/shop/classify_ecology/company",
    },
    // 监控告警
    {
        path: "/monitoring",
        file: "views/monitoring/index",
    },
    // 监控 - 数据库使用情况
    {
        path: "/monitoringdatabate",
        file: "views/monitoring/databate/index",
    },
    // 监控 - 数据库使用情况 - 详情
    {
        path: "/monitoringdatabatedetail",
        file: "views/monitoring/databate/detail",
    },
    // 监控 - 数据库使用情况 - 操作机构
    {
        path: "/monitoringdatabateorg",
        file: "views/monitoring/databate/org",
    },
    // 监控 - 数据库使用情况 - 操作机构人员
    {
        path: "/monitoringdatabateorgpersonnel",
        file: "views/monitoring/databate/orgpersonnel",
    },
    // 平台商城订单
    {
        path: "/shoporder",
        file: "views/shop/order/order",
    },
    // 平台商城订单修改
    {
        path: "/shoporderother",
        file: "views/shop/order/orderother",
    },
    // 平台商城订单修改
    {
        path: "/shoporderaddress",
        file: "views/shop/order/orderaddress",
    },
    // 南沙区地名+管理
    {
        path: "/placenameindex",
        file: "views/placename/index",
    },
    // 南沙区地名+管理 - 类型管理
    {
        path: "/placenametypeindex",
        file: "views/placename/typeindex",
    },
    // 南沙区地名+管理 - 内容管理
    {
        path: "/placenamecontentindex",
        file: "views/placename/contentindex",
    },
    // 配置的页面
    {
        path: "/allocatepage",
        file: "views/allocatepage/index",
    },
    // 畅联通
    {
        path: "/groupeasy",
        file: "views/group/easy/index",
    },
    // 畅联通 - 群管理
    {
        path: "/groupeasygroup",
        file: "views/group/easy/group",
    },
    // 畅联通 - 组管理
    {
        path: "/groupeasycombination",
        file: "views/group/easy/combination",
    },
    // 畅联通 - 公告已读统计
    {
        path: "/groupeasyannouncement",
        file: "views/group/easy/announcement",
    },
    // 畅联通 - 公告已读统计
    {
        path: "/groupeasyforward",
        file: "views/group/easy/forward",
    },

    // 地名+评论审核
    {
        path: "/placenamecomment",
        file: "views/agendaroom/placenamecomment",
    },
    {
        path: "/idcard",
        file: "views/idcard/index",
    },
    // 信息类评论审核
    {
        path: "/infocomment",
        file: "views/agendaroom/infocomment",
    },
    // 广告管理
    {
        path: "/bannerIndex",
        file: "views/banner/index",
    },
    // 精准推送管理
    {
        path: "/precisepush",
        file: "views/precisepush/index",
    },
    // 菜单管理
    {
        path: "/menuIndex",
        file: "views/menu/index",
    },
    {
        path: "/orgRegion",
        file: "views/orgRegion/index",
    },
    {
        path: "/sendview",
        file: "views/sendview/index",
    },
    {
        path: "/sendviewTable",
        file: "views/sendview/table",
    },
    {
        path: "/sendviewdetail",
        file: "views/sendview/detail",
    },
    {
        path: "/OperationServices",
        file: "views/residentdatabase/children/OperationServices",
    },
    {
        path: "/governancenetwork",
        file: "views/governancenetwork/index",
    },
    {
        path: "/governancenetworkdetail",
        file: "views/governancenetwork/detail",
    },
    {
        path: "/governancenetworkother",
        file: "views/governancenetwork/other",
    },
    {
        path: "/governancenetworkotherdetail",
        file: "views/governancenetwork/otherdetail",
    },
    // 其他类型的投票
    {
        path: "/voteotherindex",
        file: "views/voteother/index.vue",
    },
    // 会员管理
    {
        path: "/membermanagementindex",
        file: "views/membermanagement/index.vue",
    },
    {
        path: "/membermanagementrecord",
        file: "views/membermanagement/record.vue",
    },
]
